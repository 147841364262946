import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EventStatus } from '../../models/Event';
import { environment } from '../../../environments/environment';
import { WebRtcPeer } from '../../libs/WebRtcPeer';

@Injectable()
export class KurentoService {
  static ws: any;
  static wsAudio: any;
  static webRtcPeerSpeaker: WebRtcPeer;
  static webRtcPeerInterpRcv: WebRtcPeer;
  static webRtcPeerInterpSnd: WebRtcPeer;
  static webRtcPeerAudience: WebRtcPeer;

  static eventStatus: any;
  static checkStatusInterval: any;
  static checkStatusTimeInterval: number;
  static eventStarted: boolean;
  static eventRestartedByServer: boolean;
  static speakerActive: boolean;
  static websocketStatus: boolean;
  static speakerStreaming: any;
  static speakerConstraints: any;
  static interpreterJoined: boolean;
  static interpreterStreaming: { [key: string]: boolean; } = {};
  static interpreterDashStreaming: any;
  static interpreterStopped = true;
  static interpreterForbidden: boolean;
  static interpreterOnAir: boolean;
  static statsMap: any = {
    speaker: {},
    interpreter: {},
    audience: null
  };
  static activeLang: string;
  static streamWorking: boolean;

  static getConnectedUsersChange: Subject<any> = new Subject<any>();
  static eventStartedChange: Subject<boolean> = new Subject<boolean>();
  static restartEventRejectedChange: Subject<any> = new Subject<any>();
  static eventRestartedByServerChange: Subject<boolean> = new Subject<boolean>();
  static speakerActiveChange: Subject<boolean> = new Subject<boolean>();
  static eventRestartedChange: Subject<boolean> = new Subject<boolean>();
  static websocketStatusChange: Subject<boolean> = new Subject<boolean>();
  static speakerStreamingChange: Subject<any> = new Subject<any>();
  static speakerConstraintsChange: Subject<any> = new Subject<any>();
  static interpreterJoinedChange: Subject<boolean> = new Subject<boolean>();
  static interpreterStreamingChange: Subject<{ value: { [key: string]: boolean; }, response: string }> = new Subject<{ value: { [key: string]: boolean; }, response: string }>();
  static interpreterRelayChange: Subject<{ [key: string]: boolean; }> = new Subject<{ [key: string]: boolean; }>();
  static interpreterDashStreamingChange: Subject<any> = new Subject<any>();
  static interpreterStoppedChange: Subject<boolean> = new Subject<boolean>();
  static interpreterForbiddenChange: Subject<boolean> = new Subject<boolean>();
  static interpreterOnAirChange: Subject<boolean> = new Subject<boolean>();
  static statsMapChange: Subject<any> = new Subject<any>();
  static activeLangChange: Subject<string> = new Subject<string>();
  static streamWorkingChange: Subject<boolean> = new Subject<boolean>();
  static errorProcessingAnswerOnAudienceResponse: Subject<number> = new Subject<number>();

  // static userId: string;
  static eventId: string;
  static lang: string;
  static relayLang: string;
  static langPair: string;
  static sessionId: string;
  static isManager: boolean;
  static mode: string;
  static remoteURL: string;
  static audienceResponseProcessAnswerRetry = 0;

  static hostname: string = environment.wsHostname;

  constructor() {
    if (!KurentoService.ws) {
      //console.log('KurentoService: cookie:', document.cookie);
      KurentoService.startWs(null);
    }

    if (!KurentoService.wsAudio) {
      KurentoService.startWsAudio('', null);
    }
  }

  static startWsAudio(lang: string, message: any) {
    KurentoService.lang = lang;
    if (!KurentoService.wsAudio || KurentoService.wsAudio.readyState !== WebSocket.OPEN) {
      const socketAddr = 'wss://' + KurentoService.hostname + ':8444/interpreter';
      KurentoService.wsAudio = new WebSocket(socketAddr);

      KurentoService.wsAudio.onopen = () => {
        console.info('KurentoService:startWsAudio:wsAudio.onopen: Connection to', socketAddr, 'done');
        KurentoService.setWebsocketStatus(true);
        if (message && KurentoService.wsAudio.readyState === WebSocket.OPEN) {
          KurentoService.sendMessageAudio(message);
        }
      };

      KurentoService.wsAudio.onclose = () => {
        if (KurentoService.wsAudio) {
          KurentoService.wsAudio.close();
          KurentoService.setWebsocketStatus(false);
          //KurentoService.wsAudio = null;
          console.log('**************************** wsaudio onclose ops ***************************');
        }
      };

      KurentoService.wsAudio.onmessage = function (message: any): void {
        const parsedMessage = JSON.parse(message.data);
        //parsedMessage.lang = lang;
        console.info('KurentoService:startWsAudio:wsAudio.onmessage: ws2 received message:', message.data);

        KurentoService.processWsMessage(parsedMessage, function () {
          switch (parsedMessage.id) {
            case 'stopInterpreterViewer':
              KurentoService.setInterpreterStreaming(parsedMessage.eventId, parsedMessage.lang, null, false, parsedMessage.response);
              break;
            default:
              console.error('KurentoService:startWsAudio:processWsMessage: ws2 unrecognized message:', parsedMessage);
          }
        });
      };
    }
  }

  static stopWsAudio() {
    if (KurentoService.wsAudio) {
      KurentoService.wsAudio.close(); // NON FUNZIONA
      KurentoService.wsAudio = null;
    }
  }


  private static startWs(message: any) {
    KurentoService.ws = new WebSocket('wss://' + KurentoService.hostname + ':8443/speaker');


    KurentoService.ws.onopen = () => {
      console.info('KurentoService:startWs:ws.onopen: Connection to', KurentoService.hostname, 'done');
      KurentoService.setWebsocketStatus(true);
      if (message) {
        KurentoService.sendMessage(message);
      }
    };

    KurentoService.ws.onclose = () => {
      if (KurentoService.ws) {
        KurentoService.ws.close();
        KurentoService.setWebsocketStatus(false);
        //KurentoService.ws = null;
        console.log('**************************** ws onclose ops ***************************');
      }
    };

    KurentoService.ws.onmessage = function (message: any): void {
      const parsedMessage = JSON.parse(message.data);
      console.info('KurentoService:startWs:ws.onmessage: Received message:', message.data);
      KurentoService.processWsMessage(parsedMessage, function () {
        switch (parsedMessage.id) {
          case 'stopCommunication':
            //this.dispose();
            KurentoService.setSpeakerStreaming(false);
            break;
          case 'stopCommunicationHttp':
            KurentoService.setActiveLanguages(parsedMessage.eventId, parsedMessage.interpreterStreaming);
            break;
          default:
            console.error('KurentoService:startWs:ws.onmessage: Unrecognized message:', parsedMessage);
        }
      });
    };
  }

  static stopWs() {
    if (KurentoService.ws) {
      KurentoService.ws.close();
      KurentoService.ws = null;
    }
  }

  static startWsIfNotRunning() {
    if (!KurentoService.ws) {
      KurentoService.startWs(null);
    }
  }

  static startEvent() {
    const message = {
      id: 'startEvent',
      eventId: KurentoService.eventId//,
      //cookie: document.cookie
    };
    KurentoService.sendMessageAudio(message);
  }

  static stopEvent() {
    const message = {
      id: 'stopEvent',
      eventId: KurentoService.eventId//,
      //cookie: document.cookie
    };
    KurentoService.sendMessageAudio(message);
  }

  static restartEvent() {
    const message = {
      id: 'restartEvent',
      eventId: KurentoService.eventId//,
      //cookie: document.cookie
    };
    KurentoService.sendMessageAudio(message);
  }

  static eventStats(eventId: string) {
    const message = {
      id: 'eventStats',
      eventId: eventId
    };
    KurentoService.sendMessageAudio(message);
  }

  static endpointStats(eventId: string, actor: string, lang: string, n: number) {
    const message = {
      id: 'endpointStats',
      eventId: eventId,
      actor: actor,
      lang: lang,
      n: n
    };
    KurentoService.sendMessageAudio(message);
  }

  static speakerJoin(eventId: string, userId: string) {
    KurentoService.eventId = eventId;
    KurentoService.sessionId = userId;
    //const message = {
    //  id: 'speakerJoin',
    //  userId: userId,
    //  eventId: eventId,
    //  languages: languages,
    //  sessionId: userId
    //};
    //KurentoService.sendMessage(message);
  }

  static interpreterJoin(eventId: string, userId: string, languages: Array<string>) {
    const message = {
      id: 'interpreterJoin',
      userId: userId,
      eventId: eventId,
      languages: languages,
      sessionId: userId
    };
    KurentoService.sendMessageAudio(message);
  }

  static httpViewerJoin() {
    const message = {
      id: 'viewer',
      sdpOffer: false,
      eventId: KurentoService.eventId
    };
    console.log('KurentoService:httpViewerJoin: Sending httpViewerJoin');
    KurentoService.sendMessage(message);
  }

  static onSwitchLanguage(eventId: string, languages: Array<string>, activeLang: string) {
    const message = {
      id: 'switchLanguage',
      eventId: eventId,
      languages: languages,
      activeLang: activeLang,
      sessionId: KurentoService.sessionId
    };
    KurentoService.sendMessageAudio(message);
  }

  static onOfferPresenter(error, offerSdp) {
    if (error) {
      throw new Error(error);
    }

    const message = {
      id: 'presenter',
      sdpOffer: offerSdp,
      eventId: KurentoService.eventId,
      sessionId: KurentoService.sessionId,
      mode: KurentoService.mode,
      constraints: KurentoService.speakerConstraints,
      remoteURL: KurentoService.remoteURL
    };
    console.log('KurentoService:onOfferPresenter: Sending onOfferPresenter');
    KurentoService.sendMessage(message);
  }

  static onOfferPresenterAudio(error, offerSdp) {
    if (error) {
      throw new Error(error);
    }

    const message = {
      id: 'presenter',
      sdpOffer: offerSdp,
      userId: KurentoService.sessionId,
      eventId: KurentoService.eventId,
      lang: KurentoService.lang,
      langPair: KurentoService.langPair,
      sessionId: KurentoService.sessionId
    };
    console.log('KurentoService:onOfferPresenterAudio: Sending onOfferPresenterAudio');
    KurentoService.sendMessageAudio(message);
  }

  static onOfferViewer(error, offerSdp) {
    if (error) {
      throw new Error(error);
    }

    const message = {
      id: 'viewer',
      sdpOffer: offerSdp,
      eventId: KurentoService.eventId,
      sessionId: KurentoService.sessionId
    };
    console.log('KurentoService:onOfferViewer: Sending onOfferViewer');
    KurentoService.sendMessage(message);
  }

  static onViewerConstraints(isAudience = false) {
    const message = {
      id: 'viewerConstraints',
      eventId: KurentoService.eventId,
      sessionId: KurentoService.sessionId,
      isAudience: isAudience
    };
    KurentoService.sendMessage(message);
  }

  private static generateOnOfferViewerAudioMessage(offerSdp): any {
    return {
      id: 'viewer',
      sdpOffer: offerSdp,
      eventId: KurentoService.eventId,
      lang: KurentoService.lang,
      sessionId: KurentoService.sessionId,
      relay: false
    };
  }

  static onOfferViewerAudioAsAudience(error, offerSdp) {
    if (error) {
      throw new Error(error);
    }
    const message = KurentoService.generateOnOfferViewerAudioMessage(offerSdp);
    message.isAudience = true;
    console.log('KurentoService:onOfferViewerAudioAsAudience: Sending onOfferViewerAudioAsAudience');
    KurentoService.sendMessageAudio(message);
  }

  static onOfferViewerAudio(error, offerSdp) {
    if (error) {
      throw new Error(error);
    }
    console.log('KurentoService:onOfferViewerAudio: Sending onOfferViewerAudio');
    KurentoService.sendMessageAudio(KurentoService.generateOnOfferViewerAudioMessage(offerSdp));
  }

  static onOfferViewerAudioRelay(error, offerSdp) {
    if (error) {
      throw new Error(error);
    }
    const message = KurentoService.generateOnOfferViewerAudioMessage(offerSdp);
    message.isAudience = true;
    message.relay = true;
    message.lang = KurentoService.relayLang;
    console.log('KurentoService:onOfferViewerAudioRelay: Sending onOfferViewerAudioRelay');
    KurentoService.sendMessageAudio(message);
  }

  static onStop() {
    const message = {
      id: 'stop',
      eventId: KurentoService.eventId,
      sessionId: KurentoService.sessionId
    };
    KurentoService.sendMessage(message);
  }

  static onStopAudio(switchActive: boolean, switchingLang = false) {

    const message = {
      id: 'stop',
      sessionId: KurentoService.sessionId,
      eventId: KurentoService.eventId,
      switchActive: switchActive,
      lang: KurentoService.lang,
      switchingLang: switchingLang
    };
    console.log('KurentoService:onStopAudio: Sending stop audio action from session ID:', KurentoService.sessionId);
    KurentoService.sendMessageAudio(message);
  }

  static onTakeAudio(error) {
    if (error) {
      throw new Error(error);
    }

    const message = {
      id: 'switch',
      //sdpOffer: offerSdp,
      eventId: KurentoService.eventId,
      lang: KurentoService.lang,
      sessionId: KurentoService.sessionId
    };
    console.log('KurentoService:onTakeAudio: Sending take action from session ID:', KurentoService.sessionId);
    KurentoService.sendMessageAudio(message);
  }

  static onIceCandidate(candidate) {
    //console.log('KurentoService:onIceCandidate: Local candidate: ' + JSON.stringify(candidate));

    const message = {
      id: 'onIceCandidate',
      eventId: KurentoService.eventId,
      sessionId: KurentoService.sessionId,
      candidate: candidate
    };
    //console.log('KurentoService:onIceCandidate: Sending onIceCandidate');
    KurentoService.sendMessage(message);
  }

  static onIceCandidateAudio(candidate) {
    //console.log('KurentoService:onIceCandidateAudio: Audio local candidate: ' + JSON.stringify(candidate));

    const message = {
      id: 'onIceCandidate',
      candidate: candidate,
      eventId: KurentoService.eventId,
      lang: KurentoService.lang,
      sessionId: KurentoService.sessionId
    };
    //console.log('KurentoService:onIceCandidateAudio: Sending onIceCandidateAudio');
    KurentoService.sendMessageAudio(message);
  }

  private static sendCheckEventStatus(isManager = KurentoService.isManager) {
    //console.log('KurentoService:sendCheckEventStatus: Audio local candidate: ' + JSON.stringify(candidate));
    if (!KurentoService.eventId) {
      console.warn('KurentoService:sendCheckEventStatus: checkEventStatus called with no eventId');
      return;
    }

    const message = {
      id: 'checkEventStatus',
      eventId: KurentoService.eventId,
      isManager: isManager
    };
    //console.log('KurentoService:sendCheckEventStatus: Sending onIceCandidateAudio');
    KurentoService.sendMessageAudio(message);
  }
  static checkEventStatus(isManager = KurentoService.isManager, timeInterval: number = null) {
    this.sendCheckEventStatus(isManager);

    if (timeInterval && !this.checkStatusTimeInterval) {
      this.checkStatusTimeInterval = timeInterval;
    }
    if (timeInterval && this.checkStatusTimeInterval && (timeInterval < this.checkStatusTimeInterval)) {
      this.stopEventStatusPolling();
      // keep shortest, interval changed
      this.checkStatusTimeInterval = timeInterval;
    }
    if (!this.checkStatusInterval && this.checkStatusTimeInterval) {
      // Start event status polling
      this.checkStatusInterval = setInterval(() => {
        this.checkEventStatus(isManager);
      }, this.checkStatusTimeInterval);
    }
  }

  static stopEventStatusPolling() {
    clearInterval(this.checkStatusInterval);
    this.checkStatusInterval = null;
    this.checkStatusTimeInterval = null;
  }

  static checkEventStatusResponse(message: any): void {
    const response = message.response;
    KurentoService.eventStatus = response;
    if (!response || !response.eventStarted) {
      console.warn('KurentoService:checkEventStatusResponse: Event id:', message.eventId, 'not already started');
      //this.dispose();
    } else {
      //KurentoService.eventStarted = true;
      console.log(
        'KurentoService:checkEventStatusResponse: Event enabled with id:', message.eventId +
        //', activeLang:', message.activeLang);
        ', message:', message
      );

      // Check if the event status received by backend informs that interpreter is NOT ON AIR and front end dashboard
      // shows that is incorrectly ON AIR
      if (this.interpreterOnAir && response[this.activeLang] && response[this.activeLang] !== this.sessionId) {
        this.interpreterOnAirChange.next(false);
      }
    }
    KurentoService.setEventStarted(message.response.eventStarted);
    KurentoService.setSpeakerActive(message.response.speakerActive);
  }

  static startEventResponse(message: any): void {
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:startEventResponse: Call not accepted for the following reason:', errorMsg);
      //this.dispose();
    } else {
      console.log('KurentoService:startEventResponse: Event started correctly! message:', message);
      KurentoService.setEventStarted(true);
    }
  }

  static stopEventResponse(message: any): void {
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:stopEventResponse: Call not accepted for the following reason:', errorMsg);
      //this.dispose();
    } else {
      console.log('KurentoService:stopEventResponse: Event stopped correctly! message:', message);
      KurentoService.setEventStarted(false);
    }
  }

  static restartEventResponse(message: any): void {
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:restartEventResponse: Call not accepted for the following reason:', errorMsg);
      KurentoService.restartEventRejectedChange.next(message);
    } else {
      console.log('KurentoService:restartEventResponse: Event restarted correctly! message:', message);
      KurentoService.setEventStarted(true);
    }
  }

  static eventStatsResponse(message: any): void {
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:eventStatsResponse: Stats not obtained for the following reason:', errorMsg);
      //this.dispose();
    } else {
      console.log('KurentoService:eventStatsResponse: Stats:', message);
      KurentoService.setStatsMap(message.stats);
    }
  }

  static endpointStatsResponse(message: any): void {
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:endpointStatsResponse: Stats not obtained for the following reason:', errorMsg);
      //this.dispose();
    } else {
      console.log('KurentoService:endpointStatsResponse: Stats:', message);
      KurentoService.setStatsPartialMap(message.stats, message.actor, message.lang, message.n);
    }
  }

  static speakerJoinResponse(message: any): void {
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:speakerJoinResponse: Join speaker cannot be possible for the following reason:', errorMsg);
      //this.dispose();
    } else {
      console.log('KurentoService:speakerJoinResponse: Speaker joined successfully! message:', message);
      KurentoService.sessionId = message.sessionId;
      //KurentoService.setSpeakerJoined(true);
    }
  }

  static interpreterJoinResponse(message: any): void {
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:interpreterJoinResponse: Join language cannot be possible for the following reason:', errorMsg);
      //this.dispose();
    } else {
      console.log('KurentoService:interpreterJoinResponse: Interpreter joined successfully! message:', message);
      KurentoService.sessionId = message.sessionId;
      KurentoService.setInterpreterJoined(true);
      KurentoService.setSwitchLanguage(message.activeLang);
    }
  }

  static switchLanguageResponse(message: any): void {
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:switchLanguageResponse: Switch language cannot be possible for the following reason:', errorMsg);
      //this.dispose();
    } else {
      console.log('KurentoService:switchLanguageResponse: Language switched successfully! message:', message);
      KurentoService.setSwitchLanguage(message.activeLang);
    }
  }

  static speakerResponse(message: any): void {
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:speakerResponse: Call not accepted for the following reason:', errorMsg);
      //this.dispose();
    } else {
      KurentoService.webRtcPeerSpeaker.processAnswer(message.sdpAnswer);
    }
  }

  static interpreterRcvResponse(message) {
    console.log('KurentoService:interpreterRcvResponse: message:', message);
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:interpreterRcvResponse: Call not accepted for the following reason:', errorMsg);
      if (message.relay) {
        KurentoService.setInterpreterStreaming(message.eventId, message.lang, message.relay, false, message.response);
      } else {
        KurentoService.setSpeakerStreaming(false);
      }
      //this.dispose();
    } else {
      KurentoService.webRtcPeerInterpRcv.processAnswer(message.sdpAnswer, error => {
        const existError = !!error;
        if (existError) {
          console.error(
            'KurentoService:interpreterRcvResponse:processAnswer:',
            'interpreterRcvResponse: Error processing answer! error:', error
          );
        }
        if (message.relay) {
          const response = existError ? 'rejected' : message.response;
          KurentoService.setInterpreterStreaming(message.eventId, message.lang, message.relay, true, response);
        } else {
          KurentoService.setSpeakerStreaming(!existError);
        }
      });
    }
  }

  static interpreterRcvConstraints(message) {
    console.log('KurentoService:interpreterRcvConstraints: message:', message);
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:interpreterRcvConstraints: Call not accepted for the following reason:', errorMsg);
      KurentoService.setSpeakerConstraints(null);
      //this.dispose();
    } else {
      KurentoService.setSpeakerConstraints(message.constraints);
    }
  }


  static interpreterSndResponse(message: any): void {
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:interpreterSndResponse: Call not accepted for the following reason:', errorMsg);
      //KurentoService.setInterpreterForbidden(true);
      KurentoService.setInterpreterDashStreaming(false, false);
    } else {
      //KurentoService.sessionId = message.sessionId;
      KurentoService.webRtcPeerInterpSnd.processAnswer(message.sdpAnswer, error => {
        if (error) {
          console.log(
            'KurentoService:interpreterSndResponse:processAnswer:',
            'interpreterSndResponse: Error processing answer! error:', error
          );
          KurentoService.setInterpreterDashStreaming(false, false);
        } else {
          const localStream = KurentoService.webRtcPeerInterpSnd.getLocalStream();
          console.warn(
            'KurentoService:interpreterSndResponse:processAnswer: LOCAL STREAM:', localStream +
            '\nAUDIO TRACK 0:', localStream.getAudioTracks()[0] +
            '\nAUDIO TRACK 0 settings:', localStream.getAudioTracks()[0].getSettings()
          );

          KurentoService.setInterpreterDashStreaming(true, message.isOnAir);
          KurentoService.setInterpreterStreaming(message.eventId, message.lang, null, false, message.response);
        }
      });
    }
  }

  static stopInterpreterSndResponse(message: any): void {
    console.log('KurentoService:stopInterpreterSndResponse: message:', message);
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:stopInterpreterSndResponse: Call not accepted for the following reason:', errorMsg);
      KurentoService.setInterpreterStopped(false);
    } else {
      KurentoService.setInterpreterStopped(true);
    }
  }

  static interpreterSwitchResponse(message: any): void {
    if (message.response === 'accepted') {
      console.warn(
        'KurentoService:interpreterSwitchResponse: You are ON AIR! Switch accepted for sessionId:',
        message.message.sessionId + ', message::', message.message
      );
      //alert('You are ON AIR! Switch accepted for sessionId: ' + message.message.sessionId);
      KurentoService.setInterpreterOnAir(true);
      //KurentoService.setInterpreterForbidden(true);
    } else if (message.response === 'lost') {
      console.warn(
        'KurentoService:interpreterSwitchResponse:',
        'You are not ON AIR! Switch accepted for sessionId:', message.message.sessionId +
        ', message:', message.message
      );
      //alert('You are not ON AIR! Switch accepted for sessionId: ' + message.message.sessionId);
      KurentoService.setInterpreterOnAir(false);
      //KurentoService.setInterpreterForbidden(true);
    } else { // 'rejected'
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn(
        'KurentoService:interpreterSwitchResponse:',
        'Switch not accepted for the following reason:', errorMsg
      );
      //KurentoService.setInterpreterForbidden(true);
    }
  }

  static audienceResponse(message) {
    if (message.response !== 'accepted') {
      const errorMsg = message.message ? message.message : 'Unknown error';
      console.warn('KurentoService:audienceResponse: Call not accepted for the following reason:', errorMsg);
      KurentoService.setInterpreterStreaming(message.eventId, message.lang, message.relay, false, message.response);
    } else {
      if (message.sdpAnswer) {
        KurentoService.webRtcPeerAudience.processAnswer(message.sdpAnswer, error => {
          if (error) {
            console.error('KurentoService:audienceResponse:webRtcPeerAudience: ERROR! error:', error);
            if (KurentoService.audienceResponseProcessAnswerRetry < 3) {
              KurentoService.errorProcessingAnswerOnAudienceResponse.next(KurentoService.audienceResponseProcessAnswerRetry);
              KurentoService.audienceResponseProcessAnswerRetry++;
            } else {
              KurentoService.setInterpreterStreaming(message.eventId, message.lang, message.relay, false, 'rejected');
              KurentoService.audienceResponseProcessAnswerRetry = 0;
            }
          } else {
            KurentoService.audienceResponseProcessAnswerRetry = 0;
          }
        });
      }
      KurentoService.setInterpreterStreaming(message.eventId, message.lang, message.relay, true, message.response);
      console.log('KurentoService:audienceResponse: AUDIENCE RESPONSE message:', message);
    }
  }

  static setProbeResponse(message) {
    if (message.response !== 'accepted') {
      const errorMsg = message.error ? message.error : 'Unknown error';
      console.warn('KurentoService:setProbeResponse: Stream is not present:', errorMsg);
      KurentoService.setStreamWorking(false);
    } else {
      KurentoService.setStreamWorking(true);
      console.warn('KurentoService:setProbeResponse: message:', message);
    }
  }

  static getConnectedUsers(message) {
    console.log('KurentoService:getConnectedUsers: CONNECTED USERS:', message);
    KurentoService.getConnectedUsersChange.next(message);
  }

  static sendMessage(message) {
    if (!KurentoService.ws) {
      console.error('KurentoService:sendMessage: ERROR: KurentoService.ws is NOT defined');
      return;
    }

    if (KurentoService.ws.readyState === WebSocket.OPEN) {
      const jsonMessage = JSON.stringify(message);
      KurentoService.ws.send(jsonMessage);
      //KurentoService.setWebsocketStatus(true);
    } else {
      console.warn(
        'KurentoService:sendMessage: ERROR: KurentoService.ws.readyState is:',
        KurentoService.ws.readyState + '. Not OPEN.'
      );
      KurentoService.startWs(message);
      //KurentoService.setWebsocketStatus(false);
    }
  }

  static sendMessageAudio(message) {
    if (!KurentoService.wsAudio) {
      console.error('KurentoService:sendMessageAudio: ERROR: KurentoService.wsAudio is NOT defined');
      return;
    }

    if (KurentoService.wsAudio.readyState === WebSocket.OPEN) {
      const jsonMessage = JSON.stringify(message);
      KurentoService.wsAudio.send(jsonMessage);
      //KurentoService.setWebsocketStatus(true);
    } else {
      console.warn(
        'KurentoService:sendMessageAudio: ERROR: KurentoService.wsAudio.readyState is:',
        KurentoService.wsAudio.readyState + '. Not OPEN.'
      );
      KurentoService.startWsAudio(message.lang, message);
      //KurentoService.setWebsocketStatus(false);
    }
  }

  static setEventStarted(value) {
    KurentoService.eventStarted = value;
    KurentoService.eventStartedChange.next(KurentoService.eventStarted);
  }

  static setEventRestartedByServer(value) {
    KurentoService.eventRestartedByServer = value;
    KurentoService.eventRestartedByServerChange.next(KurentoService.eventRestartedByServer);
  }

  static setSpeakerActive(value) {
    KurentoService.speakerActive = value;
    KurentoService.speakerActiveChange.next(KurentoService.speakerActive);
  }


  static setWebsocketStatus(value) {
    KurentoService.websocketStatus = value;
    KurentoService.websocketStatusChange.next(KurentoService.websocketStatus);
  }

  static setSpeakerStreaming(value) {
    KurentoService.speakerStreaming = value;
    KurentoService.speakerStreamingChange.next(KurentoService.speakerStreaming);
  }

  static setSpeakerConstraints(value) {
    KurentoService.speakerConstraints = value;
    KurentoService.speakerConstraintsChange.next(KurentoService.speakerConstraints);
  }

  static setInterpreterJoined(value) {
    KurentoService.interpreterJoined = value;
    KurentoService.interpreterJoinedChange.next(KurentoService.interpreterJoined);
  }

  static setStreamWorking(value) {
    KurentoService.streamWorking = value;
    KurentoService.streamWorkingChange.next(KurentoService.streamWorking);
  }

  static setInterpreterStreaming(eventId, lang, relay, value, response) {
    console.warn(
      'KurentoService:setInterpreterStreaming: interpreter streaming response! Event id:',
      eventId + ', lang:', lang + ', relay:', relay + ', value:', value
    );
    if (eventId && lang) {
      KurentoService.interpreterStreaming[eventId + '_' + lang] = value;
      if (relay === null) {
        KurentoService.interpreterRelayChange.next(KurentoService.interpreterStreaming);
        KurentoService.interpreterStreamingChange.next({value: KurentoService.interpreterStreaming, response: response});
      } else if (relay) {
        KurentoService.interpreterRelayChange.next(KurentoService.interpreterStreaming);
      } else {
        KurentoService.interpreterStreamingChange.next({value: KurentoService.interpreterStreaming, response: response});
      }
    }
  }

  static setInterpreterDashStreaming(connected, isOnAir) {
    KurentoService.interpreterDashStreaming = {
      connected: connected,
      isOnAir: isOnAir
    };
    KurentoService.interpreterDashStreamingChange.next(KurentoService.interpreterDashStreaming);
  }

  static setInterpreterStopped(value) {
    KurentoService.interpreterStopped = value;
    KurentoService.interpreterStoppedChange.next(KurentoService.interpreterStopped);
  }

  /*
  static setInterpreterForbidden(value) {
    //if (lang) {
      KurentoService.interpreterForbidden = value;
      KurentoService.interpreterForbiddenChange.next(KurentoService.interpreterForbidden);
    //}
  }
  */

  static setInterpreterOnAir(value) {
    KurentoService.interpreterOnAir = value;
    KurentoService.interpreterOnAirChange.next(KurentoService.interpreterOnAir);
  }

  static setStatsMap(value) {
    KurentoService.statsMap = value;
    KurentoService.statsMapChange.next(KurentoService.statsMap);
  }

  static setStatsPartialMap(value, actor, lang, id) {
    if (actor === 'speaker_video') {
      KurentoService.statsMap.speaker.video = value;
    } else if (actor === 'speaker_audio') {
      KurentoService.statsMap.speaker.audio = value;
    } else if (actor === 'interpreter') {
      if (!KurentoService.statsMap.interpreter[lang]) {
        KurentoService.statsMap.interpreter[lang] = {}; // map interpreterId -> data
      }
      KurentoService.statsMap.interpreter[lang][id] = value;
    }

    KurentoService.statsMapChange.next(KurentoService.statsMap);
  }

  static setSwitchLanguage(value) {
    if (!value) {
      // On interpreterJoinResponse after reloading the page, this method is called with value undefined because the
      // activeLang is not set yet.
      // Adding the check here just in case it is called from somewhere else with value undefined
      return;
    }

    KurentoService.activeLang = value;
    KurentoService.activeLangChange.next(KurentoService.activeLang);
  }

  static setActiveLanguages(eventId, interpreterStreaming) {
    interpreterStreaming.forEach(elem => {
      KurentoService.interpreterStreaming[elem.code] = elem.active;
    });
    //if (KurentoService.eventId === eventId) {
    //  KurentoService.interpreterStreaming = interpreterStreaming;
    //}
    console.warn('KurentoService:setActiveLanguages: interpreterStreaming:', interpreterStreaming);
    KurentoService.interpreterStreamingChange.next({value: KurentoService.interpreterStreaming, response: 'accepted'});
  }

  static disposeWebRtcPeer(webRtcPeer: any) {
    if (webRtcPeer) {
      webRtcPeer.dispose();
      webRtcPeer = null;
    }
  }

  static getProviderURL(params, sanitizer) {
    // sets the external provider url if passed by param
    // p: provider (y=Youtube, v=Vimeo)
    // v: video id
    if (params['p']) {
      let providerURL = '';
      const provider = params['p'];
      if (provider === 'y') {
        providerURL = 'https://www.youtube.com/embed/';
      } else if (provider === 'v') {
        providerURL = 'https://player.vimeo.com/video/';
      }
      return params['v'] ? sanitizer.bypassSecurityTrustResourceUrl(providerURL + params['v']) : null;
    }
    return null;
  }

  private static processWsMessage(parsedMessage, otherCases): void {
    switch (parsedMessage.id) {
      case 'checkEventStatusResponse':
        KurentoService.checkEventStatusResponse(parsedMessage);
        break;
      case 'startEventResponse':
        KurentoService.startEventResponse(parsedMessage);
        break;
      case 'stopEventResponse':
        KurentoService.stopEventResponse(parsedMessage);
        break;
      case 'eventRestartedByServer':
        KurentoService.setEventRestartedByServer(true);
        break;
      case 'restartEventResponse':
        KurentoService.restartEventResponse(parsedMessage);
        break;
      case 'eventStatsResponse':
        KurentoService.eventStatsResponse(parsedMessage);
        break;
      case 'endpointStatsResponse':
        KurentoService.endpointStatsResponse(parsedMessage);
        break;
      case 'speakerResponse':
        KurentoService.speakerResponse(parsedMessage);
        break;
      case 'interpreterRcvResponse':
        KurentoService.interpreterRcvResponse(parsedMessage);
        break;
      case 'interpreterRcvConstraints':
        KurentoService.interpreterRcvConstraints(parsedMessage);
        break;
      case 'interpreterSndResponse':
        KurentoService.interpreterSndResponse(parsedMessage);
        break;
      case 'stopInterpreterSndResponse':
        KurentoService.stopInterpreterSndResponse(parsedMessage);
        break;
      case 'interpreterSwitchResponse':
        KurentoService.interpreterSwitchResponse(parsedMessage);
        break;
      case 'interpreterJoinResponse':
        KurentoService.interpreterJoinResponse(parsedMessage);
        break;
      case 'switchLanguageResponse':
        KurentoService.switchLanguageResponse(parsedMessage);
        break;
      case 'audienceResponse':
        KurentoService.audienceResponse(parsedMessage);
        break;
      case 'switchAudienceToInterpreters':
        KurentoService.setInterpreterStreaming(parsedMessage.eventId, parsedMessage.lang, null, true, 'accepted');
        break;
      case 'switchAudienceToInterpretersHttp':
        KurentoService.setActiveLanguages(parsedMessage.eventId, parsedMessage.interpreterStreaming);
        break;
      case 'videoProbeResponse':
        KurentoService.setProbeResponse(parsedMessage);
        break;
      case 'connectedUsers':
        KurentoService.getConnectedUsers(parsedMessage);
        break;
      case 'iceCandidateSpeaker':
        KurentoService.webRtcPeerSpeaker.addIceCandidate(parsedMessage.candidate);
        break;
      case 'iceCandidateInterpRcv':
        KurentoService.webRtcPeerInterpRcv.addIceCandidate(parsedMessage.candidate);
        break;
      case 'iceCandidateInterpSnd':
        KurentoService.webRtcPeerInterpSnd.addIceCandidate(parsedMessage.candidate);
        break;
      case 'iceCandidateAudience':
        KurentoService.webRtcPeerAudience.addIceCandidate(parsedMessage.candidate);
        break;
      default:
        otherCases();
    }
  }
}
