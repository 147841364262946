import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../models/User';
import { EventService } from '../event/event.service';
import { UserService } from '../user/user.service';
import { OsService } from '../util/os.service';

@Injectable({
  providedIn: 'root'
})
export class SocketsService implements OnDestroy {
  ws: any;
  user: User;
  socketMessageReceive: Subject<any> = new BehaviorSubject<any>(null);
  ready: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private subscriptions = new Subscription();
  private reconnecting: boolean;
  private lastPingTimeout;
  private lastReconnectAttemptTimeout;
  private reconnectAttempt = 1;

  constructor(
    private router: Router,
    private eventServ: EventService,
    private userServ: UserService,
    private osServ: OsService
  ) {
    this.subscriptions.add(this.userServ.userChange.subscribe((user) => {
      if (!user || !user._id || (_.get(this, 'user._id') === user._id)) {
        return;
      }

      this.user = user;
      this.connectToServer();
    }));
  }

  sendMessage(message) {
    if (!this.ws) {
      console.info('SocketsService:sendMessage: No WebSocket connection');
      return;
    }

    if (this.ws.readyState === WebSocket.OPEN) {
      const jsonMessage = JSON.stringify(message);
      this.ws.send(jsonMessage);
    } else {
      console.info(
        'SocketsService:sendMessage: ERROR WebSocket NOT OPEN!',
        'ws.readyState is:', this.ws.readyState
      );
    }
  }

  joinGroup(groupId) {
    this.sendMessage({
      messageId: 'joinGroup',
      groupId: groupId,
      from: this.user._id,
      fromName: this.user.username
    });
  }

  private connectToServer() {
    if (!this.user) {
      return;
    }

    this.clearWs();
    this.ws = new WebSocket(`wss://${ environment.wsHostname }:8491`);

    this.ws.onerror = (error) => {
      console.info(
        'SocketsService:connectToServer.ws.onerror:',
        'WebSocket error:', error
      );
      this.reconnecting = false;
      this.reconnectToServer();
    };

    this.ws.onopen = () => {
      this.ready.next(true);
      this.reconnecting = false;
      this.reconnectAttempt = 1;
      console.log(
        'SocketsService:connectToServer.ws.onopen:',
        'WebSocket connection established'
      );
      this.sendMessage({
        messageId: 'connectingToServer',
        userId: this.user._id,
        name: this.user.username,
        macOSUser: this.osServ.isMac(),
        isInterpreter: this.user.isInterpreter(),
        eventId: this.eventServ.getCurrentEvent() ? this.eventServ.getCurrentEvent()._id : null
      });
    };

    this.ws.onclose = () => {
      this.ws.removeAllListeners();
      console.log(
        'SocketsService:connectToServer.ws.onclos:',
        'WebSocket connection closed'
      );
      this.reconnectToServer();
    };

    this.ws.onmessage = (message: any) => {
      const parsedMessage = JSON.parse(message.data);

      if (parsedMessage.messageId === 'ping') {
        clearTimeout(this.lastPingTimeout);
        this.sendMessage({ messageId: 'pong' });
        this.lastPingTimeout = setTimeout(() => {
          // didn't receive ping for more than 30 seconds (server ping),
          // client or server dead, reload page
          this.setReconnectingTrue();
          location.reload();
        }, 30000);
        return;
      }

      if (parsedMessage.messageId === 'eventRestarted') {
        // If is interpreter of event, reload page
        const event = this.eventServ.getCurrentEvent();
        if (
          event && (event._id === parsedMessage.eventId) &&
          this.user.isInterpreter()
        ) {
          location.reload();
        }
      }

      if (parsedMessage.messageId === 'moveToDashboard') {
        // If is interpreter of event, move to dashboard and reload page
        if (this.user.isInterpreter()) {
          this.router.navigate(
            ['/dashboard/interpreter/events'],
            { queryParams: { forceReload: true }}
          );
        }
      }

      this.socketMessageReceive.next(parsedMessage);
    };
  }

  private setReconnectingTrue() {
    this.ready.next(false);
    this.socketMessageReceive.next(null);
    this.reconnecting = true;
  }

  private reconnectToServer() {
    if (this.reconnecting) {
      return;
    }

    clearTimeout(this.lastReconnectAttemptTimeout);
    this.setReconnectingTrue();
    this.lastReconnectAttemptTimeout = setTimeout(() => {
      if (this.reconnectAttempt < 31) this.reconnectAttempt++;
      console.log(
        'SocketsService:reconnectToServer:',
        'Reconnecting WebSocket to server'
      );
      this.connectToServer();
    }, 1000 * this.reconnectAttempt);
  }

  public clearWs() {
    clearTimeout(this.lastPingTimeout);
    clearTimeout(this.lastReconnectAttemptTimeout);

    if (this.ws) {
      this.ws.removeAllListeners();
      this.ws.close();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
