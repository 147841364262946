// /app/atatus.handler.ts
import { ErrorHandler, Injectable } from '@angular/core';
import * as atatus from 'atatus-js';
import { environment } from '../environments/environment';
import { UserService } from './services/user/user.service';

atatus.config('d062465801ad4917a6bdf63c2d56e559', {
  allowedDomains: ['ablioconference.com', 'dev.ablioconference.com', 'local.ablioconference.com'],
  version: '4.2.1'//,
  //console: true
}).install();

@Injectable()
export class AtatusErrorHandler implements ErrorHandler {
  constructor(private userServ: UserService) {}

  handleError(error: any): void {
    const err = (error.originalError || error);

    if (err) {
      // logging errors, otherwise they are not showed in browser console
      console.error('AtatusErrorHandler: ERROR! err:', (err || 'UNKNOWN ERROR!'));

      // Exceptions
      if (err.message) {
        // Automatically reload page in case of loading chunk error
        const chunkFailedMsg = /Loading chunk [\d]+ failed/;
        if (chunkFailedMsg.test(err.message)) {
          return location.reload(true);
        }

        /* Handling exceptions
         * Candidates:
         * "Cannot convert a Symbol value to a string"
         * "Error: NotAllowedError: Permission"
         * "The play() request was interrupted"
         */
        const exclude = /(QWERTY|zaloJS)/;
        if (
          (
            (typeof err.message === 'string') ||
            (err.message instanceof String)
          ) &&
          (err.message.search(exclude) > -1)
        ) {
          return;
        }
      }

      if (atatus && environment.enableProdMode) {
        // Set ignore spans for routes
        //atatus.setIgnoreSpansForRoutes([
        //  /^((?!QWERTY|zaloJS).)*$/g
        //]);

        // Set ignore URLs
        //atatus.setIgnoreUrls([
        //  /^((?!blog.ablioconference.com|wificonference.com).)*$/g
        //]);

        // User and custom data
        const user = this.userServ.getUser();
        if (user && user._id) {
          let displayName = 'Anonymous';
          if (user.firstName && user.lastName) {
            displayName = user.firstName + ' ' + user.lastName;
          }

          // Set the user
          atatus.setUser(user._id, (user.email || null), displayName);

          // Set custom data
          const customData : any = { id: user._id.toString() };
          if (user.username) customData.username = user.username;
          if (user.email) customData.email = user.email;
          if (user.mobile || user.phone) customData.phone = (user.mobile || user.phone);
          // groups: 'resellr-candidate', 'reseller', 'owner', 'event-master', 'event-manager', 'interpreter', 'admin', 'operator'
          const groups: any = user.groups;
          if (groups.indexOf('admin') > -1) {
            customData.userType = 'admin';
          } else if (groups.indexOf('operator') > -1) {
            customData.userType = 'operator';
          } else if (groups.indexOf('interpreter') > -1) {
            customData.userType = 'interpreter';
          } else if (groups.indexOf('reseller') > -1) {
            customData.userType = 'reseller';
          } else if (groups.indexOf('resellr-candidate') > -1) {
            customData.userType = 'resellr-candidate';
          } else if (groups.indexOf('owner') > -1) {
            customData.userType = 'owner';
          } else if (groups.indexOf('event-master') > -1) {
            customData.userType = 'event-master';
          } else if (groups.indexOf('event-manager') > -1) {
            customData.userType = 'event-manager';
          }
          if (user.status) customData.status = user.status;
          customData.termsAccepted = (user.terms ? true : false);
          customData.environment = (environment.name ? environment.name : 'production');
          if (user.language) customData.lang = user.language;
          customData.websiteLang = (user.websiteLanguage || 'en');
          if (user.company) customData.company = user.company;
          if (user.owner) customData.owner = user.owner.toString();
          if (user.ablioId) customData.ablioId = user.ablioId.toString();
          if (
            user.paymentMethod &&
            user.paymentMethod.card &&
            user.paymentMethod.card.stripeCustomerId
          ) {
            customData.stripeCustomerId = user.paymentMethod.card.stripeCustomerId;
          }
          atatus.setCustomData(customData);
        }

        // Set the application version
        // Note: already set above in atatus.config
        //atatus.setVersion('4.2.1');

        // Send the error to Atatus
        atatus.notify(err);
      }
    }
  }
}
