import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlashMessagesService } from "../../../../services/util/flash-messages.service";
import { KurentoService } from '../../../../services/kurento/kurento.service';

@Component({
  selector: 'app-io-device-selector',
  templateUrl: './io-device-selector.component.html',
  styleUrls: ['./io-device-selector.component.css']
})
export class IoDeviceSelectorComponent implements OnInit {

  @Input() outputHtmlElements: Array<ElementRef>;
  inputDeviceSelect: any;
  outputDeviceSelect: any;
  selectors: any;
  @Output() inputDeviceIdChange = new EventEmitter();
  @Output() outputDeviceIdChange = new EventEmitter();
  currentAudioTrack: MediaStreamTrack;
  previousInputDevices = [];
  previousOutputDevice = '';

  constructor(private flashMessagesServ: FlashMessagesService) {
  }

  ngOnInit() {
    this.inputDeviceSelect = document.getElementById('inputDevice');
    this.outputDeviceSelect = document.getElementById('outputDevice');
    this.selectors = [this.inputDeviceSelect, this.outputDeviceSelect];
    this.loadSources();
    // Detect changes on input devices (add or remove)
    navigator.mediaDevices.ondevicechange = () => {
      if (this.currentAudioTrack) {
        this.currentAudioTrack.stop();
      }
      this.loadSources();
    }
  }

  loadSources() {
    navigator.mediaDevices.getUserMedia({
      audio: true
    }).then(stream => {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          this.gotDevices(devices);
        })
        .catch(error => {
          console.error('IoDeviceSelectorComponent:loadSources:enumerateDevices: ERROR! error:', error);
        }).finally(() => {
        // immediately turn off mic after asking for permission. Otherwise, the user might think they are already recording
        stream.getAudioTracks().forEach(track => track.stop());
      });
    }).catch(error => {
      console.error('IoDeviceSelectorComponent:loadSources:getUserMedia: ERROR! error:', error);
      this.flashMessagesServ.error('There was an error trying to get audio devices: ' + error.message, { grayOut: true, neverClose: true });
      if (error.message.toLowerCase().includes('permission')) {
        this.flashMessagesServ.error('Allow microphone access in browser settings and then reload the page', { grayOut: true, neverClose: true });
      }
    });
  }

  gotDevices(deviceInfos) {
    console.log('IoDeviceSelectorComponent:gotDevices: deviceInfos', deviceInfos);
    if (!deviceInfos || !deviceInfos.length) {
      return;
    }

    let changeInputDevice = false;
    let switchToFirst = false; // if false, switch to previous selected
    // check if input device was disconnected or list of devices changed
    if (this.inputDeviceSelect && this.inputDeviceSelect.value && !deviceInfos.filter(d => d.deviceId === this.inputDeviceSelect.value).length) {
      this.flashMessagesServ.warning('The previously selected input device was disconnected. Automatically switched microphone.', {
        showCloseBtn: true,
        timeout: 5000
      });
      changeInputDevice = true;
      switchToFirst = true;
    }
    const newInputDevices = deviceInfos.filter(d => d.kind === 'audioinput').map(d => d.deviceId);
    if (!changeInputDevice && this.previousInputDevices.length && newInputDevices.length) {
      changeInputDevice = !this.compareArrays(this.previousInputDevices, newInputDevices);
    }
    this.previousInputDevices = newInputDevices;

    console.log('???DEBUG??? number of input devices: ', deviceInfos.filter(d => d.kind === 'audioinput').length);
    console.log('???DEBUG??? number of output devices: ', deviceInfos.filter(d => d.kind === 'audiooutput').length);

    // Preserve values, used later to set the values that were selected
    const previousValues = this.selectors.map(select => select.value);
    // Remove previous options in selectors. Handles being called several times to update labels
    this.selectors.forEach(select => {
      while (select.firstChild) {
        select.removeChild(select.firstChild);
      }
    });

    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      let label = deviceInfo.label;
      if (label.toLowerCase().includes('default')) {
        label += ' (OS)';
      }
      if (deviceInfo.kind === 'audioinput') {
        option.text = label || `microphone ${ this.inputDeviceSelect.length + 1 }`;
        if (!label.toLowerCase().includes('monitor')) {
          this.inputDeviceSelect.appendChild(option);
        }
      } else if (deviceInfo.kind === 'audiooutput') {
        option.text = label || `speaker ${ this.outputDeviceSelect.length + 1 }`;
        this.outputDeviceSelect.appendChild(option);
      } else {
        console.log('IoDeviceSelectorComponent:gotDevices: Some other kind of source/device: ', deviceInfo);
      }
    }
    // Setting values that were selected previously in the selectors
    this.selectors.forEach((select, selectorIndex) => {
      if (Array.prototype.slice.call(select.childNodes).some(n => n.value === previousValues[selectorIndex])) {
        select.value = previousValues[selectorIndex];
      }
    });

    let changeOutputDevice = false;
    if (!this.previousOutputDevice) {
      // First time loading dashboard, change output device to the current selection
      changeOutputDevice = true;
    }
    // Check if output device was disconnected
    if (this.outputDeviceSelect && this.outputDeviceSelect.value && !deviceInfos.filter(d => d.deviceId === this.outputDeviceSelect.value).length) {
      this.flashMessagesServ.warning('The previously selected output device was disconnected. Automatically switched output device.', {
        showCloseBtn: true,
        timeout: 5000
      });
      changeOutputDevice = true;
    }
    if (changeOutputDevice) {
      this.changeOutputDevice(true);
    }

    if (changeInputDevice) {
      this.changeInputDevice(switchToFirst);
    }
  }

  async getAudioTrack(deviceId, previousConstraints) {
    const constraints = Object.assign(previousConstraints, { deviceId: { exact: deviceId } });
    console.log('???DEBUG??? new constraints: ', constraints);
    const stream = await navigator.mediaDevices.getUserMedia({ audio: constraints });
    return stream.getAudioTracks();
  }

  async changeInputDevice(switchToFirst = false) {
    let previousSelected = this.inputDeviceSelect.value;
    if (switchToFirst) {
      if (!this.inputDeviceSelect.firstChild) {
        // No input options available
        return;
      }
      // Default is first option in Chrome. In Firefox 'Default' option doesn't exist, but will switch to first option anyway
      this.inputDeviceSelect.value = this.inputDeviceSelect.firstChild.value;
    } else if (previousSelected !== this.inputDeviceSelect.firstChild.value) {
      // This is to fix Chrome's issue. Need to change to first, and then change again to previous selected in order to make it work
      await this.changeInputDevice(true);
      this.inputDeviceSelect.value = previousSelected;
    }
    if (!KurentoService.webRtcPeerInterpSnd || !KurentoService.webRtcPeerInterpSnd.getLocalStream()) {
      return this.inputDeviceIdChange.emit(this.inputDeviceSelect.value);
    }

    const pc = KurentoService.webRtcPeerInterpSnd.peerConnection;
    const sender = pc.getSenders().find(s => {
      return s.track.kind == 'audio';
    });
    this.getAudioTrack(this.inputDeviceSelect.value, sender.track.getConstraints()).then(res => {
      console.log('???DEBUG??? MediaStreamTrack: ', res);
      const newAudioTrack = res[0];
      this.currentAudioTrack = newAudioTrack;
      sender.replaceTrack(newAudioTrack).then(() => {
        console.log('???DEBUG??? track replaced, device value: ', this.inputDeviceSelect.value);
        this.inputDeviceIdChange.emit(this.inputDeviceSelect.value);
      }).catch(e => console.log('IoDeviceSelectorComponent: Error adding track: ', e));
    }).catch(e => console.log('IoDeviceSelectorComponent: Error getAudioTrack: ', e));
  }

  // Attach audio output device to video element using device/sink ID.
  attachSinkId(element, sinkId) {
    if (typeof element.sinkId !== 'undefined') {
      element.setSinkId(sinkId)
        .then(() => {
          console.log(`Success, audio output device attached: ${ sinkId }`);
          this.outputDeviceIdChange.emit(this.outputDeviceSelect.value);
        })
        .catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${ error }`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
          this.outputDeviceSelect.selectedIndex = 0;
        });
    } else {
      console.warn('Browser does not support output device selection.');
    }
  }

  changeOutputDevice(switchToDefault = false) {
    let audioDestination = this.outputDeviceSelect.value;
    if (switchToDefault && this.outputDeviceSelect.firstChild) {
      audioDestination = this.outputDeviceSelect.value = this.outputDeviceSelect.firstChild.value;
    }
    this.outputHtmlElements.forEach(e => this.attachSinkId(e, audioDestination));
    this.previousOutputDevice = audioDestination;
  }

  private compareArrays(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // code below should never be reached, the length comparison should be enough, but just in case...
    const aSorted = [...a].sort();
    const bSorted = [...b].sort();
    for (let i = 0; i < a.length; ++i) {
      if (aSorted[i] !== bSorted[i]) return false;
    }
    return true;
  }
}
